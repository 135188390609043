import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PatientRegistration from "../dialog/PatientRegistration";
import { store } from "../../globalState";
import localData from "../../services/localStorage/localData";
const styles = {
  shadow: {
    boxShadow: "#EEE 9px -10px 5px 13px",
  },
  cursor: {
    cursor: "pointer",
  },
};

export default function PlainHeader() {
  const navigate = useNavigate();
  const [openPatientRegistration, setopenPatientRegistration] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    // check ascendUserToken exist
    if (localData.tokenExist("ascendUserToken")) setIsSignIn(true);
    if (localData.tokenExist("ascendUserData"))
      setUserData(JSON.parse(localData.tokenExist("ascendUserData")));
  }, []);

  store.subscribe(() => {
    console.log("On plain header ", store.getState());
    let { patient_sign_in } = store.getState();
    console.log(patient_sign_in);
    setIsSignIn(patient_sign_in);
    if (localData.tokenExist("ascendUserData"))
      setUserData(JSON.parse(localData.tokenExist("ascendUserData")));
  });

  const patientSignOut = () => {
    localData.destroyToken("ascendUserToken");
    localData.destroyToken("ascendUserData");
    store.dispatch({
      type: "PATIENT_SIGNOUT",
      payload: {},
    });
  };
  const checkPatientRegistration = () => {
    setopenPatientRegistration(true);
  };
  const patientRegistrationClosed = (msg) => {
    console.log("message ", msg);
    setopenPatientRegistration(false);
  };

  return (
    <div>
      <nav className="navbar bg-body-tertiary" style={styles.shadow}>
        <div className="container-fluid">
          <span
            className="navbar-brand"
            onClick={(e) => navigate("/")}
            style={styles.cursor}
          >
            <img
              src="https://elco.ascendehr.com/assets/img/login-logo1.png"
              alt="Logo"
              width="30"
              height="24"
              className="d-inline-block align-text-top"
            />
            EMR
          </span>
          {isSignIn ? (
            <span onClick={patientSignOut}>
              {" "}
              {userData ? (
                <span className="capitalize">
                  ({userData.firstName} {userData.lastName})
                </span>
              ) : (
                <></>
              )}{" "}
              Sign out
            </span>
          ) : (
            <span onClick={checkPatientRegistration}>sign in</span>
          )}
        </div>
      </nav>

      {openPatientRegistration ? (
        <PatientRegistration
          title={"Patient Registration"}
          onClose={patientRegistrationClosed}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
