import {
  getMasterData,
  submitPatientRegistration,
  getMRN,
} from "./../../services/backend/api";
import { useState, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
} from "@mui/material";
import MessageDialog from "./MessageDialog";
import { store } from "../../globalState";
import localData from "../../services/localStorage/localData";

export default function PatientRegistration({ onClose, title }) {
  const [form, setForm] = useState({});
  const [locations, setLocations] = useState([]);
  const [genders, setGenders] = useState([]);
  const [countries, setCountries] = useState([]);
  const validationFields = ["firstName", "email", "userName", "gender"];
  const [submitResponse, setSubmitResponse] = useState("");

  useEffect(() => {
    async function fetchData() {
      const data = await getMasterData();
      if (data && data.data) {
        if (data.data.masterCountry) {
          console.log("countries ", data.data.masterCountry);
          setCountries([...data.data.masterCountry]);
        }
        if (data.data.masterLocation) {
          setLocations([...data.data.masterLocation]);
        }
        if (data.data.masterGender) {
          setGenders([...data.data.masterGender]);
        }
      }
      const mrn = await getMRN();
      console.log({ mrn });
      if (mrn && mrn.data && mrn.data.uniqueId) {
        setForm((prevForm) => {
          return { ...prevForm, mrn: mrn.data.uniqueId };
        });
      }
    }
    fetchData();
  }, []);

  const addToForm = (ev, fieldName, isCheckbox) => {
    let prevForm = form;
    prevForm[fieldName] = isCheckbox ? ev.target.checked : ev.target.value;
    setForm({ ...form });
  };
  const submitForm = async () => {
    let item = validationFields.filter((item) => !form[item]);
    if (item && item.length) {
      setSubmitResponse(
        ` ${item.join(" , ")} ${item.length > 1 ? "are" : "is"} required  `,
      );
    } else {
      console.log("submit form clicked ");
      const res = await submitPatientRegistration(form);
      console.log(res);
      if (res && res.message && res.message.statusCode === 200) {
        localData.setToken("ascendUserToken", res.message.data?.id);
        localData.setToken("ascendUserData", JSON.stringify(res.message.data));
        store.dispatch({
          type: "PATIENT_SIGNIN",
          data: { id: res.message.data?.id },
        });
        setForm({});
        setTimeout(() => {
          handleClose();
        }, 2500);
        //handleClose();
      }
      setSubmitResponse(res?.message?.message);
    }
  };
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    console.log("close clicked");
    onClose();
    setOpen(false);
  };

  const dialogClose = () => {
    console.log("Dailog closed on registration");
    setSubmitResponse("");
  };
  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"lg"}>
        <DialogTitle>
          {title}
          <Button style={{ float: "right" }} onClick={handleClose}>
            Close
          </Button>
        </DialogTitle>
        <div>
          <div className="p-4 pt-0 bg-white row justify-content-start">
            <div className="container p-3">
              <div className="row justify-content-start">
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="First Name"
                    variant="standard"
                    required
                    value={form["firstName"] ? form["firstName"] : ""}
                    onChange={(ev) => addToForm(ev, "firstName", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Middle Name"
                    variant="standard"
                    value={form["middleName"] ? form["middleName"] : ""}
                    onChange={(ev) => addToForm(ev, "middleName", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Last Name"
                    variant="standard"
                    value={form["lastName"] ? form["lastName"] : ""}
                    onChange={(ev) => addToForm(ev, "lastName", false)}
                  />
                </div>
              </div>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className="w-100"
                    label="Gender"
                    select
                    defaultValue=""
                    required
                    variant="standard"
                    onChange={(ev) => addToForm(ev, "gender", false)}
                  >
                    {genders.map((option) => (
                      <MenuItem key={option.gender} value={option.id}>
                        {option.gender}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-md-4">
                  <TextField
                    type={"date"}
                    className={"w-100"}
                    variant="standard"
                    label={" "}
                    onChange={(ev) => addToForm(ev, "dob", false)}
                    helperText={"Date of Birth"}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Email"
                    variant="standard"
                    required
                    value={form["email"] ? form["email"] : ""}
                    onChange={(ev) => addToForm(ev, "email", false)}
                  />
                </div>
              </div>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Secondary Race"
                    variant="standard"
                    value={
                      form["secondaryRaceID"] ? form["secondaryRaceID"] : ""
                    }
                    onChange={(ev) => addToForm(ev, "secondaryRaceID", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Ethnicity"
                    variant="standard"
                    value={form["ethnicity"] ? form["ethnicity"] : ""}
                    onChange={(ev) => addToForm(ev, "ethnicity", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Primary Provider"
                    variant="standard"
                    value={
                      form["primaryProvider"] ? form["primaryProvider"] : ""
                    }
                    onChange={(ev) => addToForm(ev, "primaryProvider", false)}
                  />
                </div>
              </div>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Rendering Provider"
                    variant="standard"
                    value={
                      form["renderingProviderID"]
                        ? form["renderingProviderID"]
                        : ""
                    }
                    onChange={(ev) =>
                      addToForm(ev, "renderingProviderID", false)
                    }
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Primary Diagnosis"
                    variant="standard"
                    value={
                      form["primaryDiagnosis"] ? form["primaryDiagnosis"] : ""
                    }
                    onChange={(ev) => addToForm(ev, "primaryDiagnosis", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className="w-100"
                    label="Location"
                    select
                    defaultValue=""
                    variant="standard"
                    onChange={(ev) => addToForm(ev, "location", false)}
                  >
                    {locations.map((option) => (
                      <MenuItem key={option.locationName} value={option.id}>
                        {option.locationName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Nationality"
                    variant="standard"
                    select
                    value={form["nationality"] ? form["nationality"] : ""}
                    onChange={(ev) => addToForm(ev, "nationality", false)}
                  >
                    {countries.map((option) => (
                      <MenuItem key={option.countryName} value={option.id}>
                        {option.countryName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="User Name"
                    variant="standard"
                    value={form["userName"] ? form["userName"] : ""}
                    onChange={(ev) => addToForm(ev, "userName", false)}
                  />
                </div>
              </div>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className="w-100"
                    label="Govt ID Type"
                    variant="standard"
                    defaultValue={""}
                    onChange={(ev) => addToForm(ev, "govtID", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Govt ID #"
                    variant="standard"
                    value={form["govtIDNum"] ? form["govtIDNum"] : ""}
                    onChange={(ev) => addToForm(ev, "govtIDNum", false)}
                  />
                </div>
              </div>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="MRN"
                    variant="standard"
                    value={form["mrn"] ? form["mrn"] : ""}
                    onChange={(ev) => addToForm(ev, "mrn", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className={"w-100"}
                    label="Race"
                    variant="standard"
                    value={form["race"] ? form["race"] : ""}
                    onChange={(ev) => addToForm(ev, "race", false)}
                  />
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Portal Required"
                      onChange={(ev) => addToForm(ev, "isPortalRequired", true)}
                    />
                  </FormGroup>
                </div>
              </div>
              <br />
              <br />
              <h3>PHONE NUMBERS</h3>
              <div className="row justify-content-start my-2">
                <div className="col-md-4">
                  <TextField
                    className="w-100"
                    label="Contact Type"
                    defaultValue=""
                    variant="standard"
                    onChange={(ev) => addToForm(ev, "contactType", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className="w-100"
                    label="Phone Number"
                    defaultValue=""
                    variant="standard"
                    onChange={(ev) => addToForm(ev, "phoneNumber", false)}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    className="w-100"
                    label="Preference"
                    defaultValue=""
                    variant="standard"
                    onChange={(ev) => addToForm(ev, "preference", false)}
                  />
                </div>
              </div>
              <br />
              <br />
              <Button
                color="primary"
                variant="contained"
                onClick={(ev) => submitForm()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      {submitResponse ? (
        <MessageDialog
          title={"Patient Registration"}
          message={submitResponse}
          onClose={dialogClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
