import { useNavigate } from "react-router-dom";
const styles = {
  bannerBg: {
    backgroundColor: "rgba(0, 0, 0, 0.30)",
    minHeight: "500px",
  },
  headingTitle: {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.70)",
    marginBottom: "20px",
    padding: "0px",
  },
  linkBtn: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#FFF",
    width: "250px",
  },
};

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="" style={styles.bannerBg}>
        <div className="row justify-content-center p-5">
          <div className="col-xs-9 col-md-6 col-lg-4 rounded p-4 bg-white">
            <span style={styles.headingTitle}>
              Welcome to Ascend Online Doctor
            </span>
            <div className="d-flex justify-content-center mt-3">
              <span
                className="raised-button text-center btn-blue"
                style={styles.linkBtn}
                onClick={(e) => navigate("/registration/doctor")}
              >
                Doctor Registration
              </span>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <span
                className="raised-button text-center btn-red"
                style={styles.linkBtn}
                onClick={(e) => navigate("/find/doctor")}
              >
                Find Doctor
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
