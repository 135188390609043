import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import CheckoutForm from "./CheckoutForm";
import CheckoutForm2 from "./CheckoutForm2";
const stripePromise = loadStripe(
  "pk_test_51JXO8sSAgQwqbqdwlECMBapaO6czUbAJQcIvbiXcH448A3D4WYNmRbXXxkujr1wFjQEASl5KgGp8RlrI9PxbB4fX00GUEjsMu2",
);

export default function Checkout({
  client_secret,
  serviceCode,
  amount,
  staffId,
  handleClose,
}) {
  const [clientSecret, setClientSecret] = useState(client_secret);

  console.log("Checkout ", client_secret);
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance: {
      theme: "stripe",
    },
  };
  const paymentComplete = () => {
    console.log("Payment complete ");
    handleClose();
  };

  return (
    <div className="p-3 checkout-form py-2">
      <Elements stripe={stripePromise}>
        <CheckoutForm2
          client_secret={client_secret}
          serviceCode={serviceCode}
          staffId={staffId}
          amount={amount}
          paymentComplete={paymentComplete}
        />
      </Elements>
    </div>
  );
}
