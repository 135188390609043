import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import api, { getMasterData } from "../../services/backend/api";
import localData from "../../services/localStorage/localData";
import Checkout from "../stripe/Checkout";
import MessageDialog from "./MessageDialog";
export default function AppointmentDialog({
  onClose,
  title,
  dateSelected,
  dateEvent,
  staffId,
}) {
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState({});
  const [rooms, setRooms] = useState([]);
  const [locations, setLocations] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [serviceCodes, setServiceCodes] = useState([]);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [submitResponse, setSubmitResponse] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [appointmentComplete, setAppointmentComplete] = useState(false);
  const validationFields = [
    "serviceCode",
    "appointmentType",
    "location",
    "serviceLocation",
  ];

  console.log("staff id is ", staffId);
  const handleClose = (msg) => {
    console.log("close clicked");
    onClose(msg);
    // setOpen(false);
  };

  const addToForm = (ev, fieldName, isCheckbox) => {
    let prevForm = form;
    prevForm[fieldName] = isCheckbox ? ev.target.checked : ev.target.value;
    setForm({ ...form });
    if (fieldName == "isPrepaid") getAppointmentTypes();
    if (fieldName == "serviceCode") {
      console.log("service codes changed ", serviceCodes);
      let ind = serviceCodes.findIndex((sc) => sc.id == ev.target.value);
      console.log(serviceCodes[ind], serviceCodes[ind].ratePerUnit);
      setForm({
        ...form,
        appointmentFee: serviceCodes[ind].ratePerUnit,
      });
    }
  };
  const getAppointmentTypes = async () => {
    const result = await api.getAppointmentTypes({
      masterdata: "MASTERPAYMENTTYPE",
      isPrepaid: form["isPrepaid"],
      startDate: dateEvent.event.start,
      endDate: dateEvent.event.end,
      patientId: localData.tokenExist("ascendUserToken"),
    });
    setAppointmentTypes(result?.data?.PatientPayerActivities);
    if (result?.data?.PatientAddresses && result?.data?.PatientAddresses.length)
      setForm({
        ...form,
        address: result?.data?.PatientAddresses[0].fullAddress,
      });
  };
  const getServiceCodes = async () => {
    const result = await api.getServiceCodeList();
    setServiceCodes(result?.data);
  };
  const getServiceLocations = async () => {
    const result = await api.getMasterData();
    console.log("get master data ", result);
    setServiceLocations(result?.data?.masterLocation);
  };
  const getSpecialities = async () => {
    const result = await api.findSpeciality();
    setSpecialities(result?.data);
  };
  const getServiceCodeAndAppointmentFee = async () => {
    const result = await api.getServiceCodeAndAppointmentFee();
    console.log("    getServiceCodeAndAppointmentFee   ", result);
  };

  const getLocations = async () => {
    const data = await getMasterData();
    if (data && data.data) {
      if (data.data.masterLocation) {
        setLocations([...data.data.masterLocation]);
      }
    }
  };

  const setFormData = (ev, name) => {
    setStartTime(ev.target.value);
    addToForm(ev, name, false);
  };

  const bookAppointment = async () => {
    console.log("Book appointment ", form);
    let item = validationFields.filter((item) => !form[item]);
    if (item && item.length) {
      setSubmitResponse(
        ` ${item.join(" , ")} ${item.length > 1 ? "are" : "is"} required  `,
      );
      return;
    }
    let obj = [
      {
        PatientAppointmentId: null,
        AppointmentTypeID: form["appointmentType"],
        AppointmentStaffs: [{ StaffId: staffId, IsDeleted: false }],
        PatientID: localData.tokenExist("ascendUserToken"),
        ServiceLocationID: +form["serviceLocation"],
        StartDateTime: dateEvent.event.start,
        EndDateTime: dateEvent.event.end,
        IsTelehealthAppointment: form["isTelehealth"]
          ? form["isTelehealth"]
          : false,
        IsExcludedFromMileage: false,
        IsDirectService: false,
        Mileage: null,
        DriveTime: null,
        latitude: 0,
        longitude: 0,
        Notes: "",
        IsRecurrence: false,
        RoomId: form["room"],
        RecurrenceRule: null,
        DepartmentID: 26,
        LocationID: form["location"],
        IsPrepaidAppointment: form["isPrepaid"],
        ClaimID: 2304,
        CustomAddressID: null,
        CustomAddress: null,
        PatientAddressID: null,
        OfficeAddressID: 16,
      },
    ];
    const result = await api.saveAppointment(obj);
    console.log("    saveAppointment   ", result);
    if (
      result &&
      result.status == "success" &&
      result.data?.statusCode == 200
    ) {
      setAppointmentComplete(true);
    }
    //handleClose(result?.message);
    setSubmitResponse(result?.data?.message);
  };
  const dialogClose = () => {
    console.log("Dailog closed on registration");
    setSubmitResponse("");
    if (appointmentComplete) handleClose();
  };
  useEffect(() => {
    getAppointmentTypes();
    getServiceCodes();
    getServiceLocations();
    getSpecialities();
    getServiceCodeAndAppointmentFee();
    getLocations();

    if (localData.tokenExist("ascendUserData")) {
      let parsedData = JSON.parse(localData.tokenExist("ascendUserData"));
      console.log({ parsedData });
      form["patientName"] =
        parsedData.firstName +
        " " +
        parsedData.middleName +
        " " +
        parsedData.lastName;
    }
  }, []);

  useEffect(() => {
    if (dateSelected) {
      setForm({
        ...form,
        startDate: new Date(dateSelected).toLocaleDateString(),
      });
      let tempDate = new Date(dateSelected);
      let tempForm = { ...form };
      tempForm["defaultDate"] = tempDate.toISOString().substring(0, 10);
      setForm({ ...tempForm });
    }
  }, [dateSelected]);

  useEffect(() => {
    console.log("form", form);

    (async () => {
      console.log("anonymouse func called ", form["isPrepaid"]);
      if (form["isPrepaid"]) {
        const clientSecretResponse = await api.getClientSecret({
          amount: 100,
        });
        console.log(clientSecretResponse);
        console.log(clientSecretResponse.client_secret);

        if (clientSecretResponse.status === "success") {
          console.log(
            "  clientSecretResponse.status  ",
            clientSecretResponse.status,
            clientSecretResponse.client_secret,
          );
          setClientSecret(clientSecretResponse.client_secret);
        }
      }
    })();
  }, [form]);

  const checkoutComplete = async () => {
    let item = validationFields.filter((item) => !form[item]);
    if (item && item.length) {
      setSubmitResponse(
        ` ${item.join(" , ")} ${item.length > 1 ? "are" : "is"} required  `,
      );
      return;
    }
    let obj = [
      {
        PatientAppointmentId: null,
        AppointmentTypeID: form["appointmentType"],
        AppointmentStaffs: [{ StaffId: staffId, IsDeleted: false }],
        PatientID: localData.tokenExist("ascendUserToken"),
        ServiceLocationID: +form["serviceLocation"],
        StartDateTime: dateEvent.event.start,
        EndDateTime: dateEvent.event.end,
        IsTelehealthAppointment: form["isTelehealth"]
          ? form["isTelehealth"]
          : false,
        IsExcludedFromMileage: false,
        IsDirectService: false,
        Mileage: null,
        DriveTime: null,
        latitude: 0,
        longitude: 0,
        Notes: "",
        IsRecurrence: false,
        RoomId: form["room"],
        RecurrenceRule: null,
        DepartmentID: 26,
        LocationID: form["location"],
        IsPrepaidAppointment: form["isPrepaid"],
        ClaimID: 2304,
        CustomAddressID: null,
        CustomAddress: null,
        PatientAddressID: null,
        OfficeAddressID: 16,
        amount: form["appointmentFee"],
        serviceCode: form["serviceCode"],
        patientId: localData.tokenExist("ascendUserToken"),
        staffId: staffId,
      },
    ];
    const result = await api.saveAppointment(obj);
    console.log("    saveAppointment   and apply payment", result);
    //handleClose(result?.message);
    if (
      result &&
      result.status == "success" &&
      result.data?.statusCode == 200
    ) {
      setAppointmentComplete(true);
    }
    setSubmitResponse(result?.data?.message);
  };
  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"lg"}>
        <DialogTitle>
          Book Appointment
          <Button style={{ float: "right" }} onClick={handleClose}>
            Close
          </Button>
        </DialogTitle>
        <div className="container p-3">
          <div className="row justify-content-start">
            <div className="col-md-6">
              <TextField
                type={"date"}
                className={"w-100"}
                variant="standard"
                label={" "}
                onChange={(ev) => addToForm(ev, "startDate", false)}
                helperText={"Start Date"}
                value={form["defaultDate"]}
                disabled={true}
              />
            </div>
            <div className="col-md-3">
              <TextField
                type={"time"}
                className={"w-100"}
                variant="standard"
                label={" "}
                onChange={(ev) => addToForm(ev, "startTime", false)}
                helperText={"Start Time"}
                value={dateEvent.event.start.toTimeString().substring(0, 8)}
                disabled={true}
              />
            </div>
            <div className="col-md-3">
              <TextField
                type={"time"}
                className={"w-100"}
                variant="standard"
                label={" "}
                onChange={(ev) => addToForm(ev, "endTime", false)}
                helperText={"End Time"}
                value={dateEvent.event.end.toTimeString().substring(0, 8)}
                disabled={true}
              />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <TextField
                className={"w-100"}
                label="Location"
                select
                defaultValue=""
                variant="standard"
                onChange={(ev) => addToForm(ev, "location", false)}
              >
                {locations.map((option) => (
                  <MenuItem key={option.locationName} value={option.id}>
                    {option.locationName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                className={"w-100"}
                variant="standard"
                label={" "}
                helperText={"Patient Name"}
                value={form["patientName"] ? form["patientName"] : ""}
                disabled={true}
              />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Prepaid Appointment"
                  onChange={(ev) => addToForm(ev, "isPrepaid", true)}
                />
              </FormGroup>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Appointment Type"
                select
                variant="standard"
                onChange={(ev) => addToForm(ev, "appointmentType", false)}
              >
                {appointmentTypes.map((option) => (
                  <MenuItem key={option.value} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Service Codes"
                select
                variant="standard"
                onChange={(ev) => addToForm(ev, "serviceCode", false)}
              >
                <MenuItem key={""} value={""}>
                  SELECT
                </MenuItem>
                {serviceCodes.map((option) => (
                  <MenuItem key={option.value} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Service Location"
                select
                variant="standard"
                onChange={(ev) => addToForm(ev, "serviceLocation", false)}
              >
                <MenuItem key={""} value={""}>
                  SELECT
                </MenuItem>
                {serviceLocations.map((option) => (
                  <MenuItem key={option.locationName} value={option.id}>
                    {option.locationName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                className={"w-100"}
                variant="standard"
                label={"Address"}
                onChange={(ev) => addToForm(ev, "address", false)}
                value={form["address"] ? form["address"] : ""}
                disabled={true}
              />
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Speciality"
                select
                variant="standard"
                onChange={(ev) => addToForm(ev, "speciality", false)}
              >
                <MenuItem key={""} value={""}>
                  SELECT
                </MenuItem>
                {specialities.map((option) => (
                  <MenuItem key={option.departmentName} value={option.id}>
                    {option.departmentName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Select Room"
                select
                variant="standard"
                onChange={(ev) => addToForm(ev, "room", false)}
              >
                <MenuItem key={""} value={""}>
                  SELECT
                </MenuItem>
                {rooms.map((option) => (
                  <MenuItem key={option.departmentName} value={option.id}>
                    {option.departmentName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Is Telehealth Appointment"
                  onChange={(ev) => addToForm(ev, "isTelehealth", true)}
                />
              </FormGroup>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Appointment Fee $"
                variant="standard"
                value={form["appointmentFee"] ? form["appointmentFee"] : ""}
                onChange={(ev) => addToForm(ev, "appointmentFee", false)}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="w-100"
                label="Payment Type"
                select
                variant="standard"
                onChange={(ev) => addToForm(ev, "paymentType", false)}
                disabled={true}
                value={"stripe"}
              >
                <MenuItem selected={true} key={"stripe"} value={"stripe"}>
                  Stripe
                </MenuItem>
              </TextField>
            </div>
          </div>
          <div className="mt-3 mb-2">
            {form["isPrepaid"] ? (
              <Checkout
                client_secret={clientSecret}
                serviceCode={form["serviceCode"]}
                amount={form["appointmentFee"]}
                staffId={staffId}
                handleClose={checkoutComplete}
              />
            ) : (
              <>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(ev) => bookAppointment()}
                >
                  Book appointment and enroll
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
      {submitResponse ? (
        <MessageDialog
          title={"Appointment"}
          message={submitResponse}
          onClose={dialogClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
