module.exports = {
  tokenExist: (name) => {
    console.log("getting token on localStorage ", name);
    return localStorage.getItem(name);
  },
  setToken: (name, value) => {
    console.log("setting token on localStorage ", name, value);
    localStorage.setItem(name, value);
  },
  destroyToken: (name) => {
    localStorage.removeItem(name);
  },
};
