import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  patient_data: {},
  patient_sign_in: false,
};

const reducer = (state, action) => {
  console.log("reducer called ", state, action);
  switch (action.type) {
    case "PATIENT_SIGNIN":
      return {
        ...state,
        patient_sign_in: true,
        patient_data: action.data,
      };
    case "PATIENT_SIGNOUT":
      return {
        ...state,
        patient_sign_in: false,
        patient_data: {},
      };
    default:
      return state;
  }
};

//export const { incremented, decremented } = counterSlice.actions;

export const store = configureStore({
  reducer,
  preloadedState: initialState,
});

//export store;
// // Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()));

// // Still pass action objects to `dispatch`, but they're created for us
// store.dispatch(incremented());
// // {value: 1}
// store.dispatch(incremented());
// // {value: 2}
// store.dispatch(decremented());
// // {value: 1}
