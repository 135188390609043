import { Button, Dialog, DialogTitle, DialogContentText } from "@mui/material";
import { useState } from "react";
export default function MessageDialog({ onClose, title, message }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    console.log("close clicked");
    onClose();
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        {title}
        <Button style={{ float: "right" }} onClick={handleClose}>
          Close
        </Button>
      </DialogTitle>
      <DialogContentText padding={3} minWidth={200}>
        {message}
      </DialogContentText>
    </Dialog>
  );
}
