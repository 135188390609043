// import { useEffect } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DoctorCalendar from "../components/calendar/DoctorCalendar";
import AppointmentDialog from "../components/dialog/AppointmentDialog";
import MessageDialog from "../components/dialog/MessageDialog";
import Checkout from "../components/stripe/Checkout";
import api from "../services/backend/api";
import localData from "../services/localStorage/localData";

export default function DoctorProfile() {
  let { id, location } = useParams();
  const [staffId, setStaffId] = useState(id);
  const [profile, setProfile] = useState({});
  const [availability, setAvailability] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [dateEvent, setDateEvent] = useState(new Date());
  const [message, setMessage] = useState("");
  const [sk, setSk] = useState("");
  const getDoctorProfile = async (id) => {
    console.log("get doctors profile ", id);
    const data = await api.getDoctorProfile(id);
    setProfile(data.data);
  };
  const getDoctorAvailability = async (id, location) => {
    const data = await api.getDoctorAvailibility({
      staffID: id,
      locationID: location,
    });
    console.log(" getDoctorAvailibility ", data);
    setAvailability(data?.data);
  };

  const dateClicked = (ev) => {
    console.log(" dc => ", ev.event.title, ev.event.start, ev.event.end);
    if (localData.tokenExist("ascendUserToken")) {
      setDateEvent(ev);
      setSelectedDate(ev.event.start);
    } else {
      setMessage("Please sign in first");
      // setTimeout(() => {
      //   setMessage("");
      // }, 2000);
    }
  };
  const onClose = () => {
    setSelectedDate("");
  };
  useEffect(() => {
    console.log("id changed ", id);
    getDoctorProfile(id);
  }, [id]);
  useEffect(() => {
    console.log("location ", location);
    getDoctorAvailability(id, location);
  }, [location]);

  const dialogClose = () => {
    setMessage("");
  };

  return (
    <div className="container m-3 pl-5">
      <div className="row justify-content-center">
        <div className="col-md-3">
          <br />
          <br />
          <h5>
            Dr. {profile.firstName} {profile.middleName} {profile.lastName}
          </h5>
          <h6>{profile.roleName}</h6>
          <small>
            Please select from the available slot to book your appointment
          </small>
        </div>
        <div className="col-md-9">
          <DoctorCalendar
            availability={availability}
            dateClicked={dateClicked}
          />
        </div>
      </div>
      {selectedDate ? (
        <AppointmentDialog
          onClose={onClose}
          dateSelected={selectedDate}
          dateEvent={dateEvent}
          staffId={staffId}
        />
      ) : (
        <></>
      )}
      {message ? (
        <MessageDialog
          title={"Warning"}
          message={message}
          onClose={dialogClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
