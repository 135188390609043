// import "./App.css";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import DoctorLogin from "./pages/DoctorLogin";
import PatientLogin from "./pages/PatientLogin";
import Main from "./Main";
import DoctorRegistration from "./pages/DoctorRegistration";
import PlainHeader from "./components/header/PlainHeader";
import FindDoctor from "./pages/FindDoctor";
import DoctorProfile from "./pages/DoctorProfile";
import Checkout from "./components/stripe/Checkout";
// import CustomDialog from "./components/dialog/CustomDialog";

function App() {
  return useRoutes([
    {
      path: "/",
      element: <Main />,
    },
    { path: "/registration/doctor", element: <DoctorRegistration /> },
    { path: "/login/doctor", element: <DoctorLogin /> },
    { path: "/find/doctor", element: <FindDoctor /> },
    { path: "/login/patient", element: <PatientLogin /> },
    {
      path: "/profile/doctor/:id/:location",
      element: <DoctorProfile />,
    },
    {
      path: "/profile/doctor/:id/:location/:paymentresponse",
      element: <DoctorProfile />,
    },
    {
      path: "/paynow",
      element: <Checkout />,
    },
  ]);
}

const AppWrapper = () => {
  return (
    <Router>
      <PlainHeader />
      <App />
    </Router>
  );
};

export default AppWrapper;
