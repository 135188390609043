import {
  getMasterData,
  submitDoctorRegistration,
} from "./../services/backend/api";
import uploadIcon from "./../assets/images/upload-icon.png";
import { useState, useEffect, useRef } from "react";
import {
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  MenuItem,
} from "@mui/material";
import MessageDialog from "../components/dialog/MessageDialog";
import { imageToBase64 } from "../util/util";

export default function DoctorRegistration() {
  const [form, setForm] = useState({});
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [genders, setGenders] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [submitResponse, setSubmitResponse] = useState("");
  const [characterCount, setCharacterCount] = useState(1000);

  const validationFields = [
    "firstName",
    "email",
    "userName",
    "password",
    "confirmPassword",
    "roleID",
    "degreeID",
    "gender",
  ];

  useEffect(() => {
    console.log("form changed ", form);
  }, [form]);

  useEffect(() => {
    async function fetchData() {
      const data = await getMasterData();
      if (data && data.data) {
        if (data.data.masterCountry) {
          console.log("countries ", data.data.masterCountry);
          setCountries([...data.data.masterCountry]);
        }
        if (data.data.masterRoles) {
          setRoles([...data.data.masterRoles]);
        }

        if (data.data.masterLocation) {
          setLocations([...data.data.masterLocation]);
        }
        if (data.data.masterGender) {
          setGenders([...data.data.masterGender]);
        }

        if (data.data.masterDegree) {
          setDegrees([...data.data.masterDegree]);
        }
      }
    }
    fetchData();
  }, []);

  const addToForm = (ev, fieldName, isCheckbox) => {
    let prevForm = form;
    prevForm[fieldName] = isCheckbox ? ev.target.checked : ev.target.value;
    setForm({ ...form });
  };
  const submitForm = async () => {
    let item = validationFields.filter((item) => !form[item]);
    if (item && item.length) {
      setSubmitResponse(
        ` ${item.join(" , ")} ${item.length > 1 ? "are" : "is"} required  `,
      );
    } else {
      console.log("submit form clicked ");
      const res = await submitDoctorRegistration(form);
      console.log(res);
      if (res && res.message && res.message.statusCode === 200) {
        setForm({});
      }
      setSubmitResponse(res?.message?.message);
    }
  };
  const uploadIconInput = useRef();
  const triggerUploadInput = () => {
    console.log("triggerUploadInput");
    uploadIconInput.current.click();
  };
  const uploadImageChanged = async (ev) => {
    const files = ev.currentTarget.files;
    if (files.length) {
      const url = URL.createObjectURL(files[0]);
      const base64 = await imageToBase64(url);
      console.log(base64);
      let prevForm = form;
      prevForm["photoBase64"] = base64;
      setForm({ ...prevForm });
    }
  };
  const dialogClose = () => {
    console.log("Dailog closed on registration");
    setSubmitResponse("");
  };
  const limitDescription = (ev) => {
    let tempText = ev.target.value;
    if (tempText.length > 1000) return false;
    setCharacterCount(1000 - tempText.length);
    addToForm(ev, "description", false);
  };

  const removePic = () => {
    let tempForm = form;
    delete tempForm["photoBase64"];
    setForm({ ...tempForm });
  };

  return (
    <div style={{ backgroundColor: "#f4f4f4", paddingTop: "50px" }}>
      <div className="container">
        <div className="p-4 pt-0 bg-white row justify-content-center">
          <div className="container p-5">
            <h5>Doctor Registration</h5>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="First Name"
                  variant="standard"
                  required
                  value={form["firstName"] ? form["firstName"] : ""}
                  onChange={(ev) => addToForm(ev, "firstName", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Middle Name"
                  variant="standard"
                  value={form["middleName"] ? form["middleName"] : ""}
                  onChange={(ev) => addToForm(ev, "middleName", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Last Name"
                  variant="standard"
                  value={form["lastName"] ? form["lastName"] : ""}
                  onChange={(ev) => addToForm(ev, "lastName", false)}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-8">
                <TextField
                  className={"w-100"}
                  label="Address"
                  variant="standard"
                  value={form["address"] ? form["address"] : ""}
                  onChange={(ev) => addToForm(ev, "address", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Street"
                  variant="standard"
                  value={form["street"] ? form["street"] : ""}
                  onChange={(ev) => addToForm(ev, "street", false)}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className="w-100"
                  label="Country"
                  select
                  variant="standard"
                  defaultValue={""}
                  onChange={(ev) => addToForm(ev, "countryID", false)}
                >
                  {countries.map((option) => (
                    <MenuItem key={option.countryName} value={option.id}>
                      {option.countryName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="City"
                  variant="standard"
                  value={form["city"] ? form["city"] : ""}
                  onChange={(ev) => addToForm(ev, "city", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="State"
                  variant="standard"
                  value={form["state"] ? form["state"] : ""}
                  onChange={(ev) => addToForm(ev, "state", false)}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Zip"
                  variant="standard"
                  value={form["zip"] ? form["zip"] : ""}
                  onChange={(ev) => addToForm(ev, "zip", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Phone"
                  variant="standard"
                  value={form["phoneNumber"] ? form["phoneNumber"] : ""}
                  onChange={(ev) => addToForm(ev, "phoneNumber", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="NPI #"
                  variant="standard"
                  value={form["npiNumber"] ? form["npiNumber"] : ""}
                  onChange={(ev) => addToForm(ev, "npiNumber", false)}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Tax ID"
                  variant="standard"
                  value={form["taxId"] ? form["taxId"] : ""}
                  onChange={(ev) => addToForm(ev, "taxId", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  type={"date"}
                  className={"w-100"}
                  variant="standard"
                  label={" "}
                  onChange={(ev) => addToForm(ev, "dob", false)}
                  helperText={"Date of Birth"}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  type={"date"}
                  className={"w-100"}
                  variant="standard"
                  label={" "}
                  onChange={(ev) => addToForm(ev, "doj", false)}
                  helperText={"Date of Hire"}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className="w-100"
                  label="Role"
                  select
                  required
                  defaultValue=""
                  variant="standard"
                  onChange={(ev) => addToForm(ev, "roleID", false)}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.roleName} value={option.id}>
                      {option.roleName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Email"
                  variant="standard"
                  required
                  value={form["email"] ? form["email"] : ""}
                  onChange={(ev) => addToForm(ev, "email", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className="w-100"
                  label="Gender"
                  select
                  defaultValue=""
                  required
                  variant="standard"
                  onChange={(ev) => addToForm(ev, "gender", false)}
                >
                  {genders.map((option) => (
                    <MenuItem key={option.gender} value={option.id}>
                      {option.gender}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="CAQH ID"
                  variant="standard"
                  value={form["caqhid"] ? form["caqhid"] : ""}
                  onChange={(ev) => addToForm(ev, "caqhid", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Language"
                  variant="standard"
                  value={form["language"] ? form["language"] : ""}
                  onChange={(ev) => addToForm(ev, "language", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className="w-100"
                  label="Degree"
                  select
                  defaultValue=""
                  variant="standard"
                  required
                  onChange={(ev) => addToForm(ev, "degreeID", false)}
                >
                  {degrees.map((option) => (
                    <MenuItem key={option.degreeName} value={option.id}>
                      {option.degreeName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="User Name"
                  variant="standard"
                  required
                  value={form["userName"] ? form["userName"] : ""}
                  onChange={(ev) => addToForm(ev, "userName", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Password"
                  variant="standard"
                  required
                  value={form["password"] ? form["password"] : ""}
                  onChange={(ev) => addToForm(ev, "password", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Confirm Password"
                  variant="standard"
                  required
                  value={form["confirmPassword"] ? form["confirmPassword"] : ""}
                  onChange={(ev) => addToForm(ev, "confirmPassword", false)}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="BHPN Practitioner ID"
                  variant="standard"
                  value={form["bhpn"] ? form["bhpn"] : ""}
                  onChange={(ev) => addToForm(ev, "bhpn", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Experience (in years)"
                  variant="standard"
                  value={form["experience"] ? form["experience"] : ""}
                  onChange={(ev) => addToForm(ev, "experience", false)}
                />
              </div>
              <div className="col-md-3">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Telemedicine Staff"
                    onChange={(ev) =>
                      addToForm(ev, "isTeleMedicineStaff", true)
                    }
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Govt ID Type"
                  variant="standard"
                  value={form["proofId"] ? form["proofId"] : ""}
                  onChange={(ev) => addToForm(ev, "proofId", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Govt ID #"
                  variant="standard"
                  value={form["proofValue"] ? form["proofValue"] : ""}
                  onChange={(ev) => addToForm(ev, "proofValue", false)}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className={"w-100"}
                  label="Speciality"
                  variant="standard"
                  value={form["speciality"] ? form["speciality"] : ""}
                  onChange={(ev) => addToForm(ev, "speciality", false)}
                />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-md-12">
                <TextField
                  className={"w-100"}
                  label="Description"
                  variant="standard"
                  onChange={limitDescription}
                  multiline
                  rows={4}
                  value={form["description"] ? form["description"] : ""}
                  inputProps={{ maxLength: 1000 }}
                />
                <small>{characterCount} characters remaining</small>
              </div>
            </div>
            <br />
            <br />
            <h3>LOCATION</h3>
            <div className="row justify-content-between my-2">
              <div className="col-md-3">
                <TextField
                  className="w-100"
                  label="Location"
                  select
                  defaultValue=""
                  variant="standard"
                  onChange={(ev) => addToForm(ev, "location", false)}
                >
                  {locations.map((option) => (
                    <MenuItem key={option.locationName} value={option.id}>
                      {option.locationName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-3">
                <TextField
                  className="w-100"
                  label="Default Location"
                  select
                  defaultValue=""
                  variant="standard"
                  onChange={(ev) => addToForm(ev, "defaultLocation", false)}
                >
                  {locations.map((option) => (
                    <MenuItem key={option.locationName} value={option.id}>
                      {option.locationName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-3">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Rendering Provider"
                    onChange={(ev) =>
                      addToForm(ev, "isRenderingProvider", true)
                    }
                  />
                </FormGroup>
              </div>
            </div>
            <br />
            <br />
            <h3>USER PROFILE PHOTO</h3>
            <div className="row justify-content-start my-2">
              <div
                className="col-md-2"
                style={{
                  border: "1px solid #888",
                  width: "120px",
                  height: "120px",
                  textAlign: "center",
                  borderRadius: "50%",
                  lineHeight: "120px",
                  cursor: "pointer",
                }}
                onClick={triggerUploadInput}
              >
                <img
                  alt="upload icon"
                  src={uploadIcon}
                  style={{ maxWidth: "60px" }}
                />
                <input
                  type={"file"}
                  style={{ display: "none" }}
                  ref={uploadIconInput}
                  accept="image/png, image/jpeg, image/jpg, image/bmp, image/png"
                  onChange={uploadImageChanged}
                />
              </div>
              {form["photoBase64"] ? (
                <div className="col-md-4 text-center">
                  <img
                    src={form["photoBase64"]}
                    alt="uploaded pic"
                    style={{
                      maxWidth: "120px",
                      height: "auto",
                      paddingBottom: "30px",
                    }}
                  />
                  <br />
                  <button onClick={removePic} className="btn btn-info btn-sm">
                    X
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <br />
            <Button
              color="primary"
              variant="contained"
              onClick={(ev) => submitForm()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      {submitResponse.length ? (
        <MessageDialog
          title={"Doctor Registration"}
          message={submitResponse}
          onClose={dialogClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
