import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import Calendar from "react-calendar";
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";

export default function DoctorCalendar({ dateClicked, availability }) {
  const [value, onChange] = useState(new Date());
  const [events, setEvents] = useState([]);
  const fdate = new Date();
  const limitDays = 14;
  const handleDateClick = (ev) => {
    console.log("dateClicked ", ev);
    dateClicked(ev);
    onChange(ev);
  };
  useEffect(() => {
    let tempEvents = [
      {
        title: "Available",
        start: "2023-03-07T20:00:00",
        end: "2023-03-07T20:30:00",
        backgroundColor: "blue",
        textColor: "white",
      },
      {
        title: "Available",
        start: "2023-03-07T15:00:00",
        end: "2023-03-07T15:30:00",
        backgroundColor: "blue",
        textColor: "white",
      },
    ];
    if (availability.unavailable) {
      availability.unavailable.forEach((item) => {
        tempEvents.push({
          title: "booked ",
          start: item.startTime,
          end: item.endTime,
          backgroundColor: "green",
          textColor: "white",
        });
      });
    }
    if (availability.available) {
      availability.available.forEach((item) => {
        let i = new Date(item.startTime);
        for (; i.getTime() < new Date(item.endTime).getTime(); ) {
          let minSlot = new Date(i.getTime());
          minSlot.setMinutes(minSlot.getMinutes() + 30);
          let isColliding = false;
          availability.unavailable.forEach((item2) => {
            if (
              new Date(item2.startTime).getTime() <= i.getTime() &&
              i.getTime() <= new Date(item2.endTime).getTime()
            ) {
              isColliding = true;
            }
          });
          tempEvents.push({
            title: "Available",
            start: i,
            end: minSlot,
            backgroundColor: "blue",
            textColor: "white",
          });
          i = minSlot;
        }
      });
    }

    setEvents([...tempEvents]);
  }, [availability]);

  fdate.setDate(fdate.getDate() + limitDays);

  const eventClicked = (ev) => {
    console.log("eventClick ", ev.event.title, ev.event.start, ev.event.end);
    dateClicked(ev);
  };
  return (
    <div>
      {/* <Calendar
        onChange={handleDateClick}
        value={value}
        maxDate={fdate}
        minDate={new Date()}
      /> */}

      <FullCalendar
        events={events}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        eventClick={eventClicked}
        // eventContent={(ev) => console.log("event Content ")}
        slotMinTime={"10:00:00"}
        validRange={{
          start: new Date(),
          end: fdate,
        }}
      />
    </div>
  );
}
