import { Button, TextField, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import defaultImage from "./../assets/images/defaultImage.jpg";
import {
  findDoctor,
  findSpeciality,
  getMasterData,
} from "../services/backend/api";
import { useNavigate } from "react-router-dom";
import AppointmentDialog from "../components/dialog/AppointmentDialog";
import { tokenExist } from "../services/localStorage/localData";
import MessageDialog from "../components/dialog/MessageDialog";
import PatientRegistration from "../components/dialog/PatientRegistration";
import loadingIcon from "./../assets/images/rolling.gif";

export default function FindDoctor() {
  // useEffect(() => {
  //   console.log(" call apis");
  // }, []);
  const [locations, setLocations] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [searchForm, setSearchForm] = useState({});
  const [messageDialogText, setMessageDialogText] = useState("");
  const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
  const [openPatientRegistration, setOpenPatientRegistration] = useState(false);

  const navigate = useNavigate();

  const styles = {
    bgGreen: {
      backgroundColor: "#413d3d",
      border: "1px solid #413d3d",
    },
  };
  const [searchResult, setSearchResult] = useState([]);
  const searchDoctor = async () => {
    setSearchResult(...[]);
    console.log("search doctor");
    const result = await findDoctor(searchForm);
    if (result?.data) setSearchResult([...result?.data]);
    console.log(searchResult);
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getMasterData();
      if (data && data.data) {
        if (data.data.masterLocation) {
          setLocations([...data.data.masterLocation]);
        }
      }
    }
    fetchData();
    fetchSpeciality("");
    //searchDoctor();
  }, []);

  useEffect(() => {
    console.log("search form ", searchForm);
  }, [searchForm]);
  useEffect(() => {
    console.log({ openPatientRegistration });
  }, [openPatientRegistration]);

  const fetchSpeciality = async (locationID) => {
    const result = await findSpeciality({ locationID });
    console.log(result);
    if (result && result.data) {
      setSpecialities([...result.data]);
    }
  };

  const specialityChanged = (ev) => {
    let prevForm = searchForm;
    prevForm["speciality"] = ev.target.value;
    setSearchForm({ ...prevForm });
  };

  const locationChanged = (ev) => {
    let prevForm = searchForm;
    prevForm["locationID"] = ev.target.value;
    fetchSpeciality(prevForm["locationID"]);
    setSearchForm({ ...prevForm });
  };

  const appointmentDialogClosed = () => {
    console.log("appointmentDialogClosed closed on registration");
    setShowAppointmentDialog(false);
  };
  const bookAppointment = () => {
    console.log(" bookAppointment ");
  };
  const checkPatientAndOpenDialog = () => {
    const isExist = tokenExist();
    console.log({
      isExist,
    });
    if (isExist) {
      setShowAppointmentDialog(true);
    } else {
      // setMessageDialogText("Please register first");
      setOpenPatientRegistration(true);
      // setTimeout(() => {
      //   setMessageDialogText("");
      // }, 1200);
    }
  };
  const patientRegistrationClosed = (msg) => {
    console.log("message ", msg);
  };
  const showDoctorProfile = (doctorID, locationID) => {
    navigate(`/profile/doctor/${doctorID}/${locationID}`);
  };

  return (
    <div className="mx-1 my-3 row justify-content-center">
      <div
        className=" col-lg-9 col-md-10 col-sm-12 text-white pt-3 pl-3 pr-3"
        style={styles.bgGreen}
      >
        <h5>FIND A DOCTOR</h5>
        <div className="row bg-white py-2">
          <div className="col-md-3">
            <TextField
              className="w-100"
              label="Select a speciality"
              select
              defaultValue=""
              variant="outlined"
              onChange={(ev) => specialityChanged(ev)}
            >
              <MenuItem key={"1"} value={""}>
                ALL
              </MenuItem>
              {specialities.map((option) => (
                <MenuItem key={option.departmentName} value={option.id}>
                  {option.departmentName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-md-3">
            <TextField
              className="w-100"
              label="Location"
              select
              defaultValue=""
              variant="outlined"
              onChange={(ev) => locationChanged(ev)}
            >
              <MenuItem key={"1"} value={""}>
                ALL
              </MenuItem>
              {locations.map((option) => (
                <MenuItem key={option.locationName} value={option.id}>
                  {option.locationName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-md-3">
            <TextField
              label="Search"
              color="secondary"
              variant="outlined"
              onChange={(ev) => (searchForm["searchText"] = ev.target.value)}
            />
          </div>
          <div className="col-md-3 p-1 text-right">
            <Button variant="contained" onClick={searchDoctor}>
              SEARCH
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white col-lg-9 col-md-10 col-sm-12 pt-3 pl-3 pr-3 ">
        {searchResult ? (
          searchResult.map((item, index) => (
            <div key={index} className="row my-3">
              <div className="col-md-3">
                <img
                  style={{ maxWidth: "100px" }}
                  src={item.profileImage ? item.profileImage : defaultImage}
                  alt="doctor"
                />
              </div>
              <div className="col-md-3">
                <h5
                  className="text-primary cursor-p"
                  onClick={(ev) =>
                    showDoctorProfile(item.staffID, item.locationID)
                  }
                >
                  Dr. {item.name}
                </h5>
                {item.speciality ? <h6>{item.speciality}</h6> : <></>}
                <h6>Gender: {item.gender}</h6>
              </div>
              <div className="col-md-3">
                <h6>Phone: {item.phoneNumber}</h6>
                <h6>Email: {item.email}</h6>
                <h6>Address: {item.address}</h6>
              </div>
              <div className="col-md-3 pl-3">
                <Button
                  variant="contained"
                  className="ml-3"
                  color="success"
                  onClick={(ev) => {
                    console.log("open appointment dialog");
                    // implement this
                    // checkPatientAndOpenDialog();
                    //                    setShowAppointmentDialog(true);
                    showDoctorProfile(item.staffID, item.locationID);
                  }}
                >
                  Book Appointment
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <img src={loadingIcon} width="100" alt="loading" />
          </div>
        )}
      </div>
      {showAppointmentDialog ? (
        <AppointmentDialog
          title={"Book Appointment"}
          onClose={appointmentDialogClosed}
          bookAppointment={bookAppointment}
        ></AppointmentDialog>
      ) : (
        <></>
      )}
      {messageDialogText ? (
        <MessageDialog
          title={"Message"}
          message={messageDialogText}
        ></MessageDialog>
      ) : (
        <></>
      )}
      {openPatientRegistration ? (
        <PatientRegistration
          title={"Patient Registration"}
          onClose={patientRegistrationClosed}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
