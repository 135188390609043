const config = require("../../config/config");
module.exports = {
  getMasterData: () => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/getmasterdata")
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  submitDoctorRegistration: (data) => {
    console.log("submit doctors registration ", data);
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/register/doctor", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getDoctorProfile: (id) => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/profile/doctor/" + id, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getDoctorAvailibility: (data) => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/availability/doctor", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  findDoctor: (data) => {
    console.log("find doctor", data);
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/find/doctor", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  findSpeciality: (data) => {
    console.log("find speciality", data);
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/find/speciality", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getAppointmentTypes: (data) => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/appointmenttypes", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getServiceCodeList: () => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/servicecodelist", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getServiceCodeAndAppointmentFee: () => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/getServiceCodeAndAppointmentFee", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getSpecialities: () => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/specialities", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  submitPatientRegistration: (data) => {
    console.log("submit patient registration ", data);
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/register/patient", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getMRN: () => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/mrn/patient", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  saveAppointment: (data) => {
    console.log("save appointment ", data);
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/saveappointment", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
  getClientSecret: (data) => {
    return new Promise((resolve, reject) => {
      fetch(config.base_url + "/client/paymenttoken", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload: data }),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  },
};
